@font-face {
  font-family: Claudion;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
/* static fonts */
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}
@font-face {
  font-family: "Claudion";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/zatca_integration/css/fonts/claudion/Claudion.ttf") format("truetype"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff2") format("woff2"), url("/assets/zatca_integration/css/fonts/claudion/Claudion.woff") format("woff");
}